import React, { PureComponent } from "react"
import classnames from "classnames"

import css from "./index.module.scss"
import SignupLink from "../../SignupLink"
import { FaArrowRight } from "react-icons/fa"
import Grid from "../../Grid"

import ExpandIcon from "../../../assets/images/faq/icn_plus.svg"
import ShrinkIcon from "../../../assets/images/faq/icn_minus.svg"

class Faq extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      selected: -1,
    }
  }

  items = () => [
    {
      id: "how-does-your-30-day-risk-free-trial-work",
      question: "How does your 30 day risk-free trial work?",
      answer: (
        <>
          You can try ReferralCandy totally free for 30 days. At the end of the
          30 days, charges for the next month will be billed.
        </>
      ),
    },
    {
      id: "what-is-commission-charged-on",
      question: "What is commission charged on?",
      answer: (
        <>
          {" "}
          The commission is charged only on purchases by new customers referred
          to your store. Also, the commission only applies to the first three
          purchases the referred customer makes. There is no commission charged
          when advocates use their reward.
        </>
      ),
    },
    {
      id: "example-of-how-pricing-would-work",
      question: "Can you give me an example of how your pricing would work? ",
      answer: (
        <>
          {" "}
          <p>
            Your ReferralCandy bill has two parts: a base fee, and commission on
            referral sales.
          </p>
          <p>
            The base fee is a monthly charge of $49. The commissions on referral
            sales are tiered:
          </p>
          <ul>
            <li>5% on the first $1,000 referral sales of the month</li>
            <li>3.5% on the next $10,000</li>
            <li>1.5% on the next $100,000</li>
            <li>0.25% thereafter</li>
          </ul>
          <p>
            For example, let’s say ReferralCandy makes you $12,000 per month in
            new referral sales:
          </p>
          <ul>
            <li>You will be charged $49 at the START of the month</li>
            <li>
              Then you’ll be charged $415 in commissions at the END of the
              month.
              <br />( $415 = ($1,000 × 5%) + ($10,000 × 3.5%) + ($1,000 × 1.5%)
              )
            </li>
          </ul>
        </>
      ),
    },
  ]

  onSelectQuestion = (id) => () => {
    const index = this.items()
      .map((item) => item.id)
      .indexOf(id)
    if (index === this.state.selected) {
      this.setState({
        selected: -1,
      })
      return
    }

    this.setState({
      selected: index,
    })
  }

  onHashChange = (event) => {
    const topic = window.location.hash.slice(1)
    const index = this.items()
      .map((item) => item.id)
      .indexOf(topic)
    this.setState({
      selected: index,
    })
  }

  componentDidMount() {
    window.addEventListener("hashchange", this.onHashChange)
    window.addEventListener("load", this.onHashChange)
  }

  componentWillUnmount() {
    window.removeEventListener("hashchange", this.onHashChange)
    window.removeEventListener("load", this.onHashChange)
  }

  render() {
    return (
      <Grid className={css.container}>
        <h2 className={css.title}>Frequently asked questions</h2>
        <ul className={css.faq}>
          {this.items().map((item, index) => (
            <li
              id={item.id}
              key={`faq-${index}`}
              className={classnames(css.item, {
                [css.expanded]: index === this.state.selected,
              })}
            >
              <div
                className={css.question}
                onClick={this.onSelectQuestion(item.id)}
              >
                <span className={css.text}>{item.question}</span>
                <span className={css.icon}>
                  <img
                    className={css.expand_icon}
                    src={ExpandIcon}
                    alt="more question detail"
                    height="300"
                    width="300"
                  />
                  <img
                    className={css.shrink_icon}
                    src={ShrinkIcon}
                    alt="hide question detail"
                    height="300"
                    width="300"
                  />
                </span>
              </div>
              <div className={css.answer}>{item.answer}</div>
            </li>
          ))}
        </ul>
        <div className={css.cta}>
          <h3>Want to try it yourself?</h3>
          <SignupLink purpose="primary">
            Try It Now &nbsp; <FaArrowRight />
          </SignupLink>
        </div>
      </Grid>
    )
  }
}

export default Faq
