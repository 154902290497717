import React, { useState } from "react"
import classnames from "classnames"

import css from "./index.module.scss"

import SignupLink from "../../SignupLink"
import Grid from "../../Grid"
import Button from "../../Button"
import ButtonGroup from "../../ButtonGroup"

import CHECKMARK_IMAGE from "../../../assets/images/pricing/checkmark.svg"

import { FaCalculator, FaArrowRight } from "react-icons/fa"

import { PRICING_CALCULATOR_ID } from "../../../pages/pricing"

const PREMIUM_PLAN = 0
const ENTERPRISE_PLAN = 1

const PricingPlan = () => {
  const [plan, setPlan] = useState(PREMIUM_PLAN)

  return (
    <Grid className={css.container} centeredClassName={css.centered}>
      <div className={css.plan_group}>
        <ButtonGroup
          className={css.group}
          buttonClassName={css.button}
          choices={["Premium", "Enterprise"]}
          onSelect={(index) => setPlan(index)}
        />
      </div>

      <div className={css.plan_grid}>
        <div className={classnames(css.remove_on_small, css.item)}>
          <h4 className={css.label}>Plans</h4>
        </div>
        <div
          className={classnames(css.item, css.plan_column, {
            [css.active]: plan === PREMIUM_PLAN,
          })}
        >
          <div className={classnames(css.remove_on_small, css.plan_name)}>
            Premium
          </div>
          <div className={css.price_header}>
            Billed <u>Monthly</u> at
          </div>
          <div className={css.price}>
            <div>
              <span className={css.price_value}>$49</span>{" "}
              <span className={css.price_value_per_mo}>/mo</span>
            </div>
            <a
              href={`#${PRICING_CALCULATOR_ID}`}
              className={css.price_value_addendum}
            >
              + commission
            </a>
          </div>

          <SignupLink purpose="primary">
            Try Free for 30 Days &nbsp; <FaArrowRight />
          </SignupLink>

          <a className={css.disclaimer} href={`#${PRICING_CALCULATOR_ID}`}>
            <FaCalculator className={css.calculator} />
            Calculate your commission
          </a>
        </div>

        <div
          className={classnames(css.item, css.plan_column, {
            [css.active]: plan === ENTERPRISE_PLAN,
          })}
        >
          <div className={classnames(css.remove_on_small, css.plan_name)}>
            Enterprise
          </div>
          <div className={css.price_header}>
            Billed <u>Annually</u> at
          </div>
          <div className={css.price}>
            <div>
              <span className={css.price_value}>$3,999</span>{" "}
              <span className={css.price_value_per_mo}>/mo</span>
            </div>
            <div className={css.price_value_addendum}>
              <br />
            </div>
          </div>
          <Button href="https://help.referralcandy.com/support/tickets/new">
            Request Sales Demo
          </Button>
        </div>
      </div>

      <div className={css.plan_grid}>
        <div className={classnames(css.remove_on_small, css.item)}>
          <h4 className={css.label}>Features</h4>
        </div>
        <div
          className={classnames(css.item, css.plan_feature, css.plan_column, {
            [css.active]: plan === PREMIUM_PLAN,
          })}
        >
          <ul>
            <li>
              <img
                alt="checkmark"
                src={CHECKMARK_IMAGE}
                className={css.checkmark}
                height="300"
                width="300"
              />
              Unlimited customers
            </li>
            <li>
              <img
                alt="checkmark"
                src={CHECKMARK_IMAGE}
                className={css.checkmark}
                height="300"
                width="300"
              />
              Dashboard & data reports
            </li>
            <li>
              <img
                alt="checkmark"
                src={CHECKMARK_IMAGE}
                className={css.checkmark}
                height="300"
                width="300"
              />
              Live chat support
            </li>
            <li>
              <img
                alt="checkmark"
                src={CHECKMARK_IMAGE}
                className={css.checkmark}
                height="300"
                width="300"
              />
              Advanced fraud management
            </li>
          </ul>
        </div>
        <div
          className={classnames(css.item, css.plan_feature, css.plan_column, {
            [css.active]: plan === ENTERPRISE_PLAN,
          })}
        >
          <ul>
            <li>
              <img
                alt="checkmark"
                src={CHECKMARK_IMAGE}
                className={css.checkmark}
                height="300"
                width="300"
              />
              Unlimited customers
            </li>
            <li>
              <img
                alt="checkmark"
                src={CHECKMARK_IMAGE}
                className={css.checkmark}
                height="300"
                width="300"
              />
              Dashboard & data reports
            </li>
            <li>
              <img
                alt="checkmark"
                src={CHECKMARK_IMAGE}
                className={css.checkmark}
                height="300"
                width="300"
              />
              Live chat support
            </li>
            <li>
              <img
                alt="checkmark"
                src={CHECKMARK_IMAGE}
                className={css.checkmark}
                height="300"
                width="300"
              />
              Advanced fraud management
            </li>
            <li>
              <img
                alt="checkmark"
                src={CHECKMARK_IMAGE}
                className={css.checkmark}
                height="300"
                width="300"
              />
              Account manager
            </li>
            <li>
              <img
                alt="checkmark"
                src={CHECKMARK_IMAGE}
                className={css.checkmark}
                height="300"
                width="300"
              />
              Priority support
            </li>
          </ul>
        </div>
      </div>
    </Grid>
  )
}

export default PricingPlan
