import React from "react"
import css from "./index.module.scss"
import { Link } from "gatsby"
import Grid from "../../Grid"
import Button from "../../Button"

import INNOVATIVE_ICON from "../../../assets/images/icn-Solution.svg"
import PAGES_ICON from "../../../assets/images/icn-EmailsPages.svg"
import CUSTOMIZE_REWARDS_ICON from "../../../assets/images/icn-Rewards.svg"
import EMAILS_ICON from "../../../assets/images/icn-emails.svg"
import PROTECTION_ICON from "../../../assets/images/icn-FraudProtection.svg"
import APPROVE_COMMUNICATION_ICON from "../../../assets/images/icn-ChatSupport.svg"

const PricingFeatures = () => {
  return (
    <>
      <Grid className={css.container} centeredClassName={css.centered}>
        <h2 className={css.title}>Stand out with ReferralCandy</h2>
        <div className={css.text}>
          <ul className={css.items}>
            <li>
              <img
                src={INNOVATIVE_ICON}
                alt="Referralcandy pricing - referralcandy cost customer referral program software"
                height="300"
                width="300"
              />
              <div className={css.feature_header}>
                <h3 className={css.header}>Out-of-the-box solution</h3>
              </div>
              <div className={css.feature_body}>
                <p>You don’t need a developer to set up ReferralCandy</p>
              </div>
            </li>
            <li>
              <img
                src={PAGES_ICON}
                alt="Referralcandy pricing - referralcandy cost customer referral program software"
                height="300"
                width="300"
              />
              <div className={css.feature_header}>
                <h3 className={css.header}>On-brand emails and pages</h3>
              </div>
              <div className={css.feature_body}>
                <p>
                  Deliver a seamless, branded customer experience you control
                </p>
              </div>
            </li>
            <li>
              <img
                src={CUSTOMIZE_REWARDS_ICON}
                alt="Referralcandy pricing - referralcandy cost customer referral program software"
                height="300"
                width="300"
              />
              <div className={css.feature_header}>
                <h3 className={css.header}>Automated Custom Rewards</h3>
              </div>
              <div className={css.feature_body}>
                <p>Choose from cash rewards, store discounts or custom gifts</p>
              </div>
            </li>
            <li>
              <img
                src={EMAILS_ICON}
                alt="Referralcandy pricing - referralcandy cost customer referral program software"
                height="300"
                width="300"
              />
              <div className={css.feature_header}>
                <h3 className={css.header}>Unlimited email blasts</h3>
              </div>
              <div className={css.feature_body}>
                <p>Connects easily to eCommerce and marketing platforms</p>
              </div>
            </li>
            <li>
              <img
                src={PROTECTION_ICON}
                alt="Referralcandy pricing - referralcandy cost customer referral program software"
                height="300"
                width="300"
              />
              <div className={css.feature_header}>
                <h3 className={css.header}>Fraud Protection</h3>
              </div>
              <div className={css.feature_body}>
                <p>
                  Automated fraud prevention, simple tools to handle fraudsters
                </p>
              </div>
            </li>
            <li>
              <img
                src={APPROVE_COMMUNICATION_ICON}
                alt="Referralcandy pricing - referralcandy cost customer referral program software"
                height="300"
                width="300"
              />
              <div className={css.feature_header}>
                <h3 className={css.header}>Live Chat Support</h3>
              </div>
              <div className={css.feature_body}>
                <p>
                  Customer success team is ready to get you set up and growing
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div style={{ textAlign: "center" }}>
          <Button el={Link} to="/features">
            View all features
          </Button>
        </div>
      </Grid>
    </>
  )
}

export default PricingFeatures
