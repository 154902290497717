import React, { useState } from "react"
import { CSSTransition } from "react-transition-group"
import css from "./index.module.scss"

import classnames from "classnames"

import AnimateOnChange from "react-animate-on-change"
import SignupLink from "../../SignupLink"
import Grid from "../../Grid"
import { PRICING_CALCULATOR_ID } from "../../../pages/pricing"
import useDebouncedCallback from "../../../helpers/useDebouncedCallback"
import { FaArrowRight } from "react-icons/fa"

import PropTypes from "prop-types"

const REFERRAL_RATE = 0.02
const MINIMUM_SALES = 250
const MAXIMUM_SALES = 10000000
const INITIAL_SALES = 200000
const TIERS = [
  [1000, 0.05],
  [10000, 0.035],
  [100000, 0.015],
  [Infinity, 0.0025],
]

const moneyize = (number) => {
  return number.toFixed(0)
}
const calculateReferralSales = function (sales) {
  return moneyize(sales * REFERRAL_RATE)
}
const INITIAL_REFERRAL_SALES = calculateReferralSales(INITIAL_SALES)

const calculateCommission = function (sales) {
  const referralSales = calculateReferralSales(sales)

  const result = TIERS.reduce(
    (acc, tier, idx) => {
      const { amount, total, breakdown } = acc
      const threshold = tier[0]
      const rate = tier[1]
      if (amount <= 0) {
        return acc
      }
      const tierCommission = Math.min(threshold, amount) * rate
      const updatedBreakdown = breakdown.map((existingValue, breakdownIdx) =>
        breakdownIdx === idx ? tierCommission : existingValue
      )
      return {
        amount: amount - threshold,
        total: total + tierCommission,
        breakdown: updatedBreakdown,
      }
    },
    {
      amount: referralSales,
      total: 0,
      breakdown: [0, 0, 0, 0],
    }
  )

  return {
    total: moneyize(result.total),
    breakdown: result.breakdown.map((value) => moneyize(value)),
  }
}
const INITIAL_COMMISSION = calculateCommission(INITIAL_SALES)

const GiantInput = ({ className, value, ...props }) => {
  return (
    <input
      className={classnames(className, css.input, css.xlarge)}
      value={value || ""}
      {...props}
    />
  )
}

const VALIDATION_CLASSNAMES = {
  enter: css.in,
  enterActive: css.in_active,
  exitActive: css.out_active,
}

const PricingCalculator = ({ name }) => {
  const [sales, setSales] = useState(INITIAL_SALES)
  const [error, setError] = useState(false)
  const [referralSales, setReferralSales] = useState(INITIAL_REFERRAL_SALES)
  const [commission, setCommission] = useState(INITIAL_COMMISSION)
  const [previousReferralSales, setPreviousReferralSales] = useState(
    INITIAL_REFERRAL_SALES
  )
  const [previousCommission, setPreviousCommission] = useState(
    INITIAL_COMMISSION
  )

  const debouncedCallback = useDebouncedCallback(
    (value) => {
      if (value < MINIMUM_SALES || value > MAXIMUM_SALES) {
        setError(true)
        setReferralSales(calculateReferralSales(0))
        setCommission(calculateCommission(0))
      } else {
        setError(false)
        setReferralSales(calculateReferralSales(value))
        setCommission(calculateCommission(value))
      }
    },
    300,
    []
  )

  const handleOnChange = (e) => {
    const value = e.target.value
    const n = parseInt(value, 10)
    if (isNaN(n) || value.startsWith("0")) {
      setSales(value)
      debouncedCallback(0)
    } else {
      setSales(n)
      debouncedCallback(n)
    }
  }

  return (
    <Grid className={css.container}>
      <div className={css.input_section}>
        <h2 id={PRICING_CALCULATOR_ID}>
          Calculate your ROI with ReferralCandy
        </h2>
        <label htmlFor={name} className={css.subheader}>
          Enter your current monthly sales
        </label>
        <div className={css.input_money}>
          <GiantInput
            type="number"
            id={name}
            placeholder="Monthly sales"
            min={MINIMUM_SALES}
            max={MAXIMUM_SALES}
            value={sales}
            onChange={handleOnChange}
          />
          <CSSTransition
            in={error}
            classNames={VALIDATION_CLASSNAMES}
            timeout={500}
            key="input_money"
            mountOnEnter
            unmountOnExit
          >
            <div className={css.speech_bubble}>
              Please input a minimum of ${MINIMUM_SALES.toLocaleString("en-US")}{" "}
              and a maximum of ${MAXIMUM_SALES.toLocaleString("en-US")}
            </div>
          </CSSTransition>
        </div>
      </div>

      <div className={css.results_section}>
        <div className={classnames(css.result, css.sales)}>
          <div className={css.result_header}>
            Estimated Sales from ReferralCandy
          </div>
          <div className={css.disclaimer}>
            Assuming {REFERRAL_RATE * 100}% referral rate
          </div>
          <AnimateOnChange
            baseClassName={css.sales_number}
            animationClassName={css.bounce}
            animate={referralSales !== previousReferralSales}
            customTag="div"
            onAnimationEnd={() => setPreviousReferralSales(referralSales)}
          >
            ${referralSales}/mo
          </AnimateOnChange>
        </div>

        <div className={classnames(css.result, css.commission)}>
          <div className={css.result_header}>You pay</div>
          <div>
            <span className={css.commission_number}>$49/mo</span>{" "}
            <AnimateOnChange
              baseClassName={css.addendum}
              animationClassName={css.subtle}
              animate={commission.total !== previousCommission.total}
              onAnimationEnd={() => setPreviousCommission(commission)}
              customTag="div"
            >
              + ${commission.total} commission
            </AnimateOnChange>
          </div>
        </div>
      </div>

      <div className={css.commission_section}>
        <p className={css.subheader}>How commission is calculated</p>
        <div className={css.grid}>
          <div>
            <strong>Referral Sales Tiers</strong>
          </div>
          <div className={css.gridcenter}>
            <strong>Tiered Commission Rate</strong>
          </div>
          <div className={css.gridend}>
            <strong>Cost</strong>
          </div>
          <div>First $1k</div>
          <div className={css.gridcenter}>5%</div>
          <div className={css.gridend}>
            <AnimateOnChange
              animationClassName={css.subtle}
              animate={
                commission.breakdown[0] !== previousCommission.breakdown[0]
              }
              customTag="div"
              onAnimationEnd={() => setPreviousCommission(commission)}
            >
              ${commission.breakdown[0]}
            </AnimateOnChange>
          </div>
          <div>Next $10k</div>
          <div className={css.gridcenter}>3.5%</div>
          <div className={css.gridend}>
            <AnimateOnChange
              animationClassName={css.subtle}
              animate={
                commission.breakdown[1] !== previousCommission.breakdown[1]
              }
              customTag="div"
              onAnimationEnd={() => setPreviousCommission(commission)}
            >
              ${commission.breakdown[1]}
            </AnimateOnChange>
          </div>
          <div>Next $100k</div>
          <div className={css.gridcenter}>1.5%</div>
          <div className={css.gridend}>
            <AnimateOnChange
              animationClassName={css.subtle}
              animate={
                commission.breakdown[2] !== previousCommission.breakdown[2]
              }
              customTag="div"
              onAnimationEnd={() => setPreviousCommission(commission)}
            >
              ${commission.breakdown[2]}
            </AnimateOnChange>
          </div>
          <div>Everything Above</div>
          <div className={css.gridcenter}>0.25%</div>
          <div className={css.gridend}>
            <AnimateOnChange
              animationClassName={css.subtle}
              animate={
                commission.breakdown[3] !== previousCommission.breakdown[3]
              }
              customTag="div"
              onAnimationEnd={() => setPreviousCommission(commission)}
            >
              ${commission.breakdown[3]}
            </AnimateOnChange>
          </div>
        </div>
      </div>

      <div className={css.cta_section}>
        <SignupLink purpose="primary">
          Try Free for 30 Days &nbsp; <FaArrowRight />
        </SignupLink>
      </div>
    </Grid>
  )
}

PricingCalculator.propTypes = {
  name: PropTypes.string.isRequired, // Unique ID for label + input
}

export default PricingCalculator
