import React from "react"
import Layout from "../components/Layout"

import PricingHeader from "../components/Sections/PricingHeader"
import PricingPlan from "../components/Sections/PricingPlan"
import PricingOnlineStores from "../components/Sections/PricingOnlineStores"
import PricingCalculator from "../components/Sections/PricingCalculator"
import GradientBackground from "../components/Sections/GradientBackground"
import PricingMerchantSuccessStory from "../components/Sections/PricingMerchantSuccessStory"
import PricingFeatures from "../components/Sections/PricingFeatures"
import PricingFAQ from "../components/Sections/PricingFAQ"

export const PRICING_CALCULATOR_ID = "roi-calc"

const metadata = {
  title:
    "ReferralCandy Pricing | Customer Referral Program Software for eCommerce",
  description:
    "How much does ReferralCandy cost? See ReferralCandy pricing plans and estimate your referral ROI with our pricing calculator.",
}

export default (props) => {
  return (
    <Layout metadata={metadata} pathname={props.location.pathname}>
      <PricingHeader />
      <PricingPlan />
      <PricingOnlineStores />
      <PricingCalculator name="pricing-page-monthly-sales" />
      <GradientBackground>
        <PricingMerchantSuccessStory />
        <PricingFeatures />
      </GradientBackground>
      <PricingFAQ />
    </Layout>
  )
}
