import React, { useRef, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import css from "./index.module.scss"
import useIntersectionObserver from "../../../helpers/useIntersectionObserver"

import Grid from "../../Grid"

import TweetEmbed from "react-tweet-embed"

const query = graphql`
  query {
    sleepphones_avatar: file(
      relativePath: {
        eq: "images/testimonials/Referralcandy pricing - sleepphones recommend referralcandy referral program software.png"
      }
    ) {
      childImageSharp {
        fixed(width: 80) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

const onTweetLoadSuccess = (entry) => {
  const animationClass = css.visible
  const classList = entry.classList
  if (!classList.contains(animationClass)) {
    classList.add(animationClass)
  }
}

const Tweets = () => {
  const container = useRef()
  const [visible, setVisible] = useState(false)

  useIntersectionObserver({
    refs: [container],
    onIntersect: () => setVisible(true),
  })

  return (
    <div ref={container} className={css.tweets}>
      {visible ? (
        <>
          <TweetEmbed
            className={css.tweet}
            onTweetLoadSuccess={onTweetLoadSuccess}
            id="991501717756837891"
            options={{ cards: "hidden" }}
          />
          <TweetEmbed
            className={css.tweet}
            onTweetLoadSuccess={onTweetLoadSuccess}
            id="1067187494918197248"
            options={{ cards: "hidden" }}
          />
        </>
      ) : null}
    </div>
  )
}

const PricingMerchantSuccessStory = () => {
  const data = useStaticQuery(query)

  return (
    <Grid className={css.container} centeredClassName={css.centered}>
      <Tweets />
      <blockquote className={css.quote_block}>
        <p className={css.quote_text}>
          ReferralCandy has been helpful for our small but growing company,{" "}
          <a
            href="https://www.sleepphones.com/"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            SleepPhones
          </a>
          . It has allowed us to put an important element of our customer
          experience on autopilot while still adding value to our process.
        </p>
        <footer className={css.source}>
          <div className={css.avatar_container}>
            <Img
              fixed={data["sleepphones_avatar"].childImageSharp.fixed}
              alt="Referralcandy pricing - sleepphones recommend referralcandy referral program software"
            />
          </div>
          <cite>
            <p className={css.name}>Darlene Galiszewski</p>
            Marketing Specialist, SleepPhones
          </cite>
        </footer>
      </blockquote>
    </Grid>
  )
}

export default PricingMerchantSuccessStory
