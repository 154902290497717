import { useCallback, useRef } from "react"

function useDebouncedCallback(
  callback, // function
  delay, // number
  deps // array<string>
) {
  const functionTimeoutHandler = useRef(null)
  const debouncedFunction = useCallback(callback, deps)

  const cancelDebouncedCallback = useCallback(() => {
    clearTimeout(functionTimeoutHandler.current)
    functionTimeoutHandler.current = null
  }, [])

  const debouncedCallback = (...args) => {
    clearTimeout(functionTimeoutHandler.current)
    functionTimeoutHandler.current = setTimeout(() => {
      debouncedFunction(...args)
      cancelDebouncedCallback()
    }, delay)
  }

  return debouncedCallback
}

export default useDebouncedCallback
