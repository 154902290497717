import React, { PureComponent } from "react"
import classnames from "classnames"

import css from "./index.module.scss"

import Button from "../Button"

/**
 * <ButtonGroup
 *    className='groupClass'
 *    buttonClassName='individualButtonClass'
 *    choices=['Choice A', 'Choice B', 'Choice C']
 *    onSelect=(index) => { console.log(index) } />
 */
class ButtonGroup extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      selected: 0,
    }
  }

  onButtonClick = (index) => () => {
    const { onSelect } = this.props
    this.setState({ selected: index })
    if (onSelect) onSelect(index)
  }

  render() {
    const { choices, className, buttonClassName } = this.props
    const { selected } = this.state

    if (!choices || choices.length < 2) {
      return null
    }

    return (
      <div className={classnames(css.group, className)}>
        {choices.map((choice, index) => (
          <Button
            key={`choice-${index}`}
            onClick={this.onButtonClick(index)}
            className={classnames(css.button, buttonClassName, {
              [css.active]: index === selected,
            })}
          >
            {choice}
          </Button>
        ))}
      </div>
    )
  }
}

export default ButtonGroup
