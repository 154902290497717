import { useEffect } from "react"

/**
 * useIntersectionObserver is used for elements that you want to enact onIntersect
 * function upon when it first scrolls into view
 * onIntersect is only enacted once, and then is unbound
 *
 * @param {React$Ref[]} refs - Array of refs to look at
 * @param {Function} onIntersect - The behaviour that you want to enact when it scrolls into view
 * @param {Number} threshold - The amount of the element that needs to be visible to enact the behaviour. defaults to 0.2
 * @returns {Function} A function to unbind this event
 */
export const onAppear = (refs, onIntersect, threshold = 0) => {
  const observer = new IntersectionObserver(
    (entries) => {
      for (let i = 0; i < entries.length; i++) {
        const entry = entries[i]
        if (entry.isIntersecting) {
          onIntersect(entry)
          observer.unobserve(entry.target)
        }
      }
    },
    {
      threshold: [threshold],
    }
  )

  for (let i = 0; i < refs.length; i++) {
    observer.observe(refs[i])
  }
  const clearObservers = () => {
    for (let i = 0; i < refs.length; i++) {
      observer.unobserve(refs[i])
    }
  }
  return clearObservers
}

const useIntersectionObserver = ({ refs, onIntersect, threshold = 0 }) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        for (let i = 0; i < entries.length; i++) {
          const entry = entries[i]
          if (entry.isIntersecting) {
            onIntersect(entry)
            observer.unobserve(entry.target)
          }
        }
      },
      {
        threshold: [threshold],
      }
    )

    for (let i = 0; i < refs.length; i++) {
      observer.observe(refs[i].current)
    }

    const clearObservers = () => {
      for (let i = 0; i < refs.length; i++) {
        observer.unobserve(refs[i].current)
      }
    }
    return clearObservers
  })
}

export default useIntersectionObserver

/**
 * Add a Class on Scroll Into View
 *
 * @param  {React$Ref[]} refs - Array of refs to look at
 * @param  {string} classToAdd - The class to add, e.g. make visible class
 * @returns {Function} A function to unbind this event
 */
// export const useAddClassOnScrollIntoView = ({ refs, classToAdd }) => {
//   return useIntersectionObserver({
//     refs,
//     onIntersect: (entry) => {
//       const classList = entry.target.classList
//       if (!classList.contains(classToAdd)) {
//         classList.add(classToAdd)
//       }
//     },
//     threshold: 0.2 })
// }
